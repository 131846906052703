<template>
  <div class="main-app-container">
    <div class="primary-app-content">
      <app-header />
      <slot></slot>
    </div>
    <app-footer />
  </div>
</template>

<style scoped>
.primary-app-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 80%;
}
</style>
